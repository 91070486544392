$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});

window.destroy = function(url, type, id) {
  document.getElementById("delete-"+id).innerHTML = "<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Loading...";

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-danger mr-2 ml-2',
      cancelButton: 'btn btn-success mr-2 ml-2'
    },
    buttonsStyling: false
  })

  swalWithBootstrapButtons.fire({
    title: 'Are you sure?',
    text: "You won't be able to return this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete!',
    cancelButtonText: 'No, cancel!',
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
      $.ajax({
        type:type,
        url:url,
        success: function(data) {
          if (data.err != '') {
            Swal.fire({
              icon: 'error',
              title: data.err,
            }).then((result) => {
              if (result.value) {
                // window.location.reload();
              }
            });
          }else{
            Swal.fire({
              icon: 'success',
              title: 'Delete Data Successfully',
            }).then((result) => {
              if (result.value) {
                if (data.url != '') {
                  window.location.reload();
                }else{

                }
              }
            });
          }
        }
      });
    } else if (
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalWithBootstrapButtons.fire(
        'Canceled',
        'Your Data is Safe :)',
        'error'
      )
    }
    // document.getElementById("delete-"+id).innerHTML = "Hapus";
    document.getElementById("delete-"+id).innerHTML = "<i class='fas fa-trash-alt'></i>";
  })
}

window.change_version = function (keyword) {
  var keyword = $('#version_id').val();

  $.ajax({
    type:'GET',
    url:'change_version',
    data: {
      keyword: keyword
    },
    success: function(data) {
      if (data.err != '') {
        $(".main-sidebar-container").empty();
        $(".main-sidebar-container").append(data);

        nav_active()
      } else {

      }
    }
  });
}

window.change_content = function (keyword) {
  $.ajax({
    type:'GET',
    url:'change_content',
    data: {
      keyword: keyword
    },
    success: function(data) {
      if (data.err != '') {
        $(".content").empty();
        $(".content").append(data);
      } else {

      }
    }
  });
}

window.add_form_body_parameters = function () {
  $.ajax({
    type:'GET',
    url:'/add_form_body_parameters',
    success: function(data) {
      if (data.err != '') {
        $("#form-body-parameters").append(data);
      } else {

      }
    }
  });
}

window.add_form_request_headers = function () {
  $.ajax({
    type:'GET',
    url:'/add_form_request_headers',
    success: function(data) {
      if (data.err != '') {
        $("#form-request-headers").append(data);
      } else {

      }
    }
  });
}

window.submit_endpoints = function () {
  var data = $('.row.form-body-parameters').map(function() {
    return {
      key: $(this).find('[name="key_body_parameters"]').val(),
      type: $(this).find('[name="type_body_parameters"]').val(),
      description: $(this).find('[name="description_body_parameters"]').val()
    };
  }).get();
  $('#body_parameters').val('');
  $('#body_parameters').val(JSON.stringify(data));
  console.log(data);

  var data = $('.row.form-request-headers').map(function() {
    return {
      authorization: $(this).find('[name="authorization_request_headers"]').val(),
      account_token: $(this).find('[name="account_token_request_headers"]').val()
    };
  }).get();
  $('#request_headers').val('');
  $('#request_headers').val(JSON.stringify(data));
  console.log(data);

  $(".form-endpoints").submit();
}

window.nav_active = function () {
  var btns = document.getElementsByClassName("nav-link ml-n2");
  for (var i = 0; i < btns.length; i++) {
    btns[i].addEventListener("click", function() {
      var current = document.getElementsByClassName("active");
      if(current.length != 0){
        current[0].className = current[0].className.replace(" active", "");
      }
      this.className += " active";
    });
  }
}

$( document ).ready(function() {
  nav_active()

  // var navs = document.getElementsByClassName("nav-custom");
  // for (var i = 0; i < navs.length; i++) {
  //   navs[i].addEventListener("click", function() {
  //     var current = document.getElementsByClassName("nav-link-active");
  //     if(current.length != 0){
  //       current[0].className = current[0].className.replace(" nav-link-active", "");
  //     }
  //     this.className += " nav-link-active";
  //   });
  // }
});
